<template>
    <div>
        <inner-page-card heading="View Participants" :link-back="true">

            <custom-vue-table :show-search-box="false" :fields="fields" :url="participantList"
                              :per-page="10" class="d-sm-none"
                              ref="table">
                <template slot="actions" slot-scope="props">
                    <div class="btn-group">
                        <btn size="sm" v-if="props.rowData.is_cancelled"
                             text="Cancel Reason" color="secondary" @click="showCancelReason(props.rowData)"/>
                        <btn size="sm" v-if="props.rowData.refund_status !== 'Completed'"
                             text="Update" color="secondary" @click="setUpdate(props.rowData)"/>
                        <btn size="sm" v-else text="View Details" color="secondary"
                             @click="$router.push({path: '/refund/' + $route.params.id + '/participants/' + props.rowData.id + '/details/'})"/>
                    </div>
                </template>
                <template slot="is_cancelled" slot-scope="props">
                    <div class="text-center">
                        <span v-if="props.rowData.is_cancelled">Yes</span>
                        <span v-else>No</span>
                    </div>
                </template>
            </custom-vue-table>

            <modal class="c-modal no-close" :show-additional-buttons="false" :no-close-on-backdrop="false"
                   title="Cancelled Reason" ref="cancelReasonModal" width="40r" header-color="">
                <p class="fs-1">
                    {{ cancelledReason }}
                </p>
                <div class="text-right mt-4">
                    <btn size="md" class="btn-basic-b bg-hover-secondary-600 w-10r"
                         border-radius="1" :disabled="loading" @click="$refs.cancelReasonModal.close()"
                         text="Close" type="button"/>
                </div>
            </modal>

            <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="updateModal" width="30r"
                   header-color="">
                <b-form class="" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
                        :initial-data="editingItem">
                    <h3 class="text-center font-inter-semi-bold">
                        Auction ID &nbsp;:&nbsp;
                        <span>{{ model.auction }}</span>
                    </h3>
                    <div class="text-center mb-3">
                        <span>Participant name</span>&nbsp;:&nbsp;
                        <span class="font-inter-semi-bold">{{ model.name }}</span>
                    </div>

                    <validated-file-input placeholder="Request Document" class="c-file-input"
                                          v-model="model.refund_request_document">
                        <template #append>
                            <div class="form-file-input-tag fl-x-cc text-white px-3">
                                <p class="m-0">Upload</p>
                                <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                            </div>
                        </template>
                    </validated-file-input>

                    <validated-text-area class="text-4 form-textarea-md" color="secondary"
                                         label="Remarks" v-model="model.refund_remarks" :rules="{required: true}">
                        <template #append>
                            <span><i class="fa fa-check-circle fs-lg-5 text-success"></i></span>
                        </template>
                    </validated-text-area>

                    <validated-select class="c-input-select-b" v-model="model.refund_status"
                                      placeholder="Status" :rules="{required: true}"
                                      :options="statusOptions"></validated-select>

                    <div class="mt-5">
                        <btn size="md" block text="Update" class="py-3 bg-secondary-600" border-radius="1"
                             :disabled="loading" :loading="loading" loading-text="Saving..."/>

                        <btn size="md" block class="mt-3 btn-basic-b py-3 bg-hover-secondary-600"
                             border-radius="1" @showCancelReason="showCancelReason"
                             :disabled="loading" @setUpdate="setUpdate"
                             @click="$refs.updateModal.close()"
                             text="Cancel" type="button"/>
                    </div>

                </b-form>
            </modal>
            <participants-mobile-view ref="participantMobileView" @setUpdate="setUpdate" class="d-lg-none"/>
        </inner-page-card>
    </div>
</template>

<script>
import urls from '../../data/urls';
import ParticipantsMobileView from './ParticipantsMobileView';

export default {
    name       : 'ViewParticipantsPage',
    components : { ParticipantsMobileView },
    data () {
        return {
            loading       : false,
            editingItem   : null,
            id            : this.$route.params.id,
            addUrl        : urls.auction.refund.refundUpdate,
            statusOptions : [
                { label : 'Completed', value : 'Completed' },
                { label : 'On Hold', value : 'On Hold' }
            ],
            fields : [
                {
                    name  : 'sl_no',
                    title : 'Sl No'
                },
                {
                    name  : 'participant_id',
                    title : 'Bidder ID'
                },
                {
                    name  : 'name',
                    title : 'Name'
                },
                {
                    name  : 'company',
                    title : 'Company'
                },
                {
                    name  : 'email',
                    title : 'EMail'
                },
                {
                    name  : 'mobile',
                    title : 'Mobile'
                },
                {
                    name  : 'refund_status',
                    title : 'Refund Status'
                },
                {
                    name       : '__slot:is_cancelled',
                    titleClass : 'w-7r',
                    title      : 'Is Cancelled'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'w-5r center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ],
            participantList    : urls.auction.refund.refundInitiatedList + '?auction=' + this.$route.params.id,
            cancelledReason    : '',
            participantDetails : urls.auction.refund.participantDetails
        };
    },

    methods : {

        showCancelReason (rowData) {
            this.cancelledReason = '';
            if (rowData.is_cancelled) {
                this.cancelledReason = rowData.cancelled_reason;
                this.$refs.cancelReasonModal.show();
            } else {
                this.$notify('Not a cancelled auction', 'Message', { type : 'warning' });
            }
        },

        setUpdate (rowData) {
            this.editingItem = { ...rowData };
            this.$refs.updateModal.show();
            this.$refs.participantMobileView.loadData();
        },

        formSuccess () {
            this.$notify('Successfully Updated', 'Success', { type : 'success' });
            this.$refs.updateModal.close();
            this.$refs.table.refreshTable();
            this.$refs.participantMobileView.loadData();
        }
    }
};
</script>

<style scoped>

</style>
