<template>

    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-4">

                <div class="gr-3-cols">

                    <div class="fl-gr-1 b-1">
                        <div class="text-center">
                            <div class="pos-r py-2 fl-j-c">
                                <span class="font-inter-medium">Sl No</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1">
                        <div class="text-center">
                            <div class="pos-r py-2 fl-j-c">
                                <span class="font-inter-medium">Bidder ID</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1">
                        <div class="text-center">
                            <div class="pos-r py-2 fl-j-c">
                                <span class="font-inter-medium">Refund Status</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="b-1 bs-3">
                    <div class="gr-3-cols font-inter-medium c-line-bottom">

                        <div class="br-1 py-2">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r fl-j-c">
                                    <div class=""></div>
                                    {{ i.sl_no }}
                                </div>
                            </div>
                        </div>

                        <div class="br-1 fl-eqh-c py-2">
                            <div class="pos-r fl-j-c">
                                <div class="br-1 capitalize-string"></div>
                                {{ i.participant_id }}
                            </div>
                        </div>

                        <div class="pl-2 py-2">
                            <div class="pos-r fl-j-c">
                                <div class="line-right-1"></div>
                                {{ i.refund_status }}
                            </div>
                        </div>

                    </div>

                    <div class="row fs--1 ">
                        <span class="w-100 col-6">
                            <div class="p-2">
                               <span class="font-inter-medium">Name : </span> <span
                                class="ml-2">{{ i.name }}</span>
                            </div>
                        </span>

                        <span class="b-1 w-100 col-12">
                            <div class="p-2">
                               <span class="font-inter-medium">E-Mail : </span> <span class="ml-2">{{ i.email }}</span>
                            </div>
                        </span>

                        <span class="b-1 w-100 col-6">
                            <div class="p-2">
                               <span class="font-inter-medium">Mobile : </span> <span class="ml-2">{{ i.mobile }}</span>
                            </div>
                        </span>

                        <span class="b-1 w-100 col-6">
                            <div class="p-2">
                               <span class="font-inter-medium">Company : </span> <span class="ml-2">{{
                                    i.company
                                }}</span>
                            </div>
                        </span>
                        <span class="b-1 w-100 col-6">
                            <div class="p-2">
                                <span class="font-inter-medium">Is Cancelled : </span>
                               <span v-if="i.is_cancelled">Yes</span>
                                <span v-else>No</span>
                            </div>
                        </span>

                        <span class="col-12">
                            <div class="p-1 text-center py-3 b-1">
                                <div class="btn-group">
                                    <btn size="sm" v-if="i.is_cancelled"
                                         text="Cancel Reason" color="secondary" @click="showCancelReason(i)"/>
                                    <btn size="sm" v-if="i.refund_status !== 'Completed'"
                                         text="Update" color="secondary" @click="setUpdate(i)"/>
                                    <btn size="sm" icon="fa fa-eye" color="secondary"
                                         @click="$router.push({path: '/refund/' + $route.params.id + '/participants/' + i.id + '/details/'})"/>
                                </div>
                            </div>
                        </span>
                    </div>

                </div>
            </div>

            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name : 'ParticipantsMobileView',

    data () {
        return {
            prev        : '',
            next        : '',
            page        : '1',
            per_page    : '10',
            loading     : true,
            error       : false,
            details     : null,
            editingItem : null,
            addUrl      : urls.auction.refund.refundUpdate
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.auction.refund.refundInitiatedList, {
                params : {
                    auction  : that.$route.params.id,
                    page     : that.page,
                    per_page : that.per_page
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },

        toggleUserActiveStatus (i) {
            this.items[i].active = !this.items[i].active;
        },
        showCancelReason (rowData) {
            this.$emit('showCancelReason', rowData);
        },

        setUpdate (rowData) {
            this.$emit('setUpdate', rowData);
        }

    }
};
</script>

<style scoped lang="scss">
.gr-4-cols {
    display: grid;
    grid-template-columns:repeat(4, 1fr);
}

.gr-3-cols {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
}

.c-line-bottom {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.3);
    }
}

.icon-danger {
    i {
        color: var(--color-danger) !important;
    }
}

.icon-primary {
    i {
        color: var(--color-primary) !important;
    }
}
</style>
